<template>
  <div>
    <el-table
      :data="tableData"
      style="width: 100%"
      stripe
      border
      :height="tableHeight"
    >
      <el-table-column type="index" label="序号" width="80"> </el-table-column>
      <el-table-column
        prop="name"
        label="属性名称"
        show-overflow-tooltip
        width="120"
      >
      </el-table-column>
      <el-table-column prop="value" label="权限配置" show-overflow-tooltip>
          <template slot-scope="scope">
              <div class="flexBox">
                  <div v-for="(item) in scope.row.value" :key="item" class="smallBox color1">
                      <div class="left">{{item}}</div>
                      <div class="right"><i class="el-icon-close"></i></div>
                  </div>
              </div>
          </template>
      </el-table-column>
      <el-table-column label="操作" fixed="right" v-slot="scope" width="80">
        <div class="option-list-box">
          <el-link
            :underline="false"
            type="primary"
            @click="goToEdit(scope.row)"
            >编辑</el-link
          >
        </div>
      </el-table-column>
    </el-table>
    <el-dialog
      title="强度等级配置"
      :visible.sync="editRoleDialogVisible"
      width="800px"
      center
    >
      <el-checkbox-group v-model="checkList">
        <el-checkbox v-for="item in accessConfiguration" :label="item" :key="item">{{item}}</el-checkbox>
      </el-checkbox-group>

      <span slot="footer" class="dialog-footer">
        <el-button @click="editRoleDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="confirmEditHandle">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tableData: [
        { name: "强度等级",value:["C20","C25","C30","C35","C40","C45","C50","C55","C60"] },
        { name: "耐久指标" },
        { name: "混凝土特性" },
      ],
      editRoleDialogVisible: false,
      checkList: [],
      accessConfiguration: ["C20","C25","C30","C35","C40","C45","C50","C55","C60"],
    };
  },
  mounted() {},
  methods: {
    goToEdit(row) {
      this.editRoleDialogVisible = true;
    },
  },
};
</script>


<style lang="less" scoped>
.flexBox{
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    .smallBox{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding: 5px;
        margin-right: 20px;
        .left{
            margin-right: 20px;
        }
    }
    .color1{
        color: #2d7afb;
        border: 1px solid #d9ecff;
        background-color: #e7f8fb;
        border-radius: 5px;
    }
    .color2{
        color: #ff9a08;
        border: 1px solid #faeedb;
        background-color: #fcf8ec;
        border-radius: 5px;
    }
    .color3{
        color: #41d69c;
        border: 1px solid #bef1dd;
        background-color: #eafff5;
        border-radius: 5px;
    }
}
</style>